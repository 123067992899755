<template>
  <div data-app id="app">
    <model-viewer
      :src="glbSource"
      shadow-intensity="0"
      camera-controls
      touch-action="pan-y"
      @progress="modelProgress($event)"
      style="width: 100%; height: 100%"
    />

    <div id="bottom-right">
      <div>
        <img style="height: 36px; width: 36px" src="numitzmatics-logo.png" />
      </div>
      <div style="margin-top: -5px; color: #d2d5d4; font-size: 0.65em">
        Numitzmatics
      </div>
    </div>

    <div
      style="display: flex; flex-direction: row; align-items: center"
      v-if="activeAccount"
      id="top-left"
    >
      <span>{{ getActiveAccount() }}</span>
      <div @click="disconnectWallet" style="cursor: pointer;">
        <svg-icon
          style="margin-left: 4px; margin-top: 3px; width: 14px"
          type="mdi"
          :path="logoutIcon"
        ></svg-icon>
      </div>
    </div>

    <div id="top-right">
      <v-btn
        @click="connectWallet"
        v-if="!activeAccount"
        elevation="2"
        outlined
        style="color: white; font-size: 0.75em"
        >Connect Wallet</v-btn
      >
      <div style="color: #d2d5d4">
        <div
          v-if="activeAccount && coinData.length == 0"
          style="display: flex; flex-direction: column; align-items: end"
        >
          {{ getActiveAccount() }} does not own any coin<br />
          <a
            class="notokens-link"
            href="https://objkt.com/collection/KT1CSYVDyhokL7iaA4ea7Rbp1Hq3uTQvV1nJ"
            target="_blank"
          >
            Visit Objkt.com Numitzmatics Collection</a
          >
        </div>
        <div
          v-if="activeAccount && coinData.length > 0"
          style="display: flex; flex-direction: column; align-items: end"
        >
          <v-autocomplete
            dark
            style="width: 130px; color: #d2d4d5; text-align: right"
            outlined
            @change="changeSelected()"
            dense
            v-model="selectedCoin"
            :items="coinData"
            item-text="Name"
            item-value="Name"
            label="Select coin"
            return-object
            single-line
          ></v-autocomplete>
          <div>
            <TProgress
              v-if="modelLoadProgress < 100"
              min="0"
              max="100"
              :value.sync="modelLoadProgress"
              style="
                padding: 0px;
                margin-left: 2px;
                margin-right: 2px;
                width: 126px;
                margin-top: -24px;
              "
              :showValue="false"
              color="blue"
              pct="true"
              label=""
            />
          </div>

          <div
            v-show="
              selectedCoin.Name &&
              (modelLoadProgress == 100 || modelLoadProgress == 0) &&
              selectedCoin.Desc
            "
            style="
              margin-top: -20px;
              text-align: right;
              min-width: 130px;
              padding: 5px;
            "
          >
            <div>{{ selectedCoin.Desc }}</div>
            <div>{{ selectedCoin.Origin }}</div>
            <div>{{ selectedCoin.Materal }}</div>
            <div>{{ selectedCoin.Date }}</div>
            <div>Obverse: {{ selectedCoin.Obverse }}</div>
            <div>Reverse: {{ selectedCoin.Reverse }}</div>
            <div></div>
            <div style="margin-top: 10px">
              <v-btn
                style="margin-right: 4px; color: white"
                :disabled="selectedIndex == 0"
                x-small
                outlined
                @click="prevCoin()"
                >Prev</v-btn
              >
              <v-btn
                style="color: white"
                :disabled="selectedIndex == coinData.length - 1"
                x-small
                outlined
                @click="nextCoin()"
                >Next</v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="true" id="bottom-left">
      <a
        href="https://objkt.com/collection/KT1CSYVDyhokL7iaA4ea7Rbp1Hq3uTQvV1nJ"
        target="_blank"
        aria-label="social-link"
        style="color: white; margin-bottom: 10px"
      >
        <img
          style="width: 52px"
          :src="objktImg"
          @mouseover="objktImg = objktImgHover"
          @mouseleave="objktImg = objktImgOrig"
        />
      </a>
    </div>
  </div>
</template>

<script>
import TProgress from "./components/TProgress.vue";
import { DAppClient, NetworkType } from "@airgap/beacon-sdk";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiLogout } from "@mdi/js";

export default {
  name: "App",
  components: {
    SvgIcon,
    TProgress,
  },
  data: () => ({
    CONTRACT_ADDRESS: "KT1CSYVDyhokL7iaA4ea7Rbp1Hq3uTQvV1nJ",
    coinData: [],
    selectedCoin: {
      Name: undefined,
      Desc: undefined,
    },
    selectedIndex: 0,
    glbSource:
      "https://delphin.fra1.cdn.digitaloceanspaces.com/numitzmatics/%23N00008.glb",
    //glbSource: undefined,
    modelLoadProgress: 0,
    objktImg: "/images/objkt.png",
    objktImgHover: "/images/objkt_hover.png",
    objktImgOrig: "/images/objkt.png",
    logoutIcon: mdiLogout,
    wallet: undefined,
    activeAccount: undefined,
    client: undefined,
  }),
  async created() {
    this.client = new DAppClient({
      name: "Tezos España",
      network: NetworkType.MAINNET,
    });
    const activeAccount = await this.client.getActiveAccount();
    if (activeAccount) {
      // User already has account connected, everything is ready
      // You can now do an operation request, sign request, or send another permission request to switch wallet
      console.log("Already connected:", activeAccount.address);
      this.activeAccount = activeAccount.address;
      this.getAccountBalance();
    } else {
      // The user is not connected. A button should be displayed where the user can connect to his wallet.
      console.log("Not connected!");
    }
  },
  mounted() {},
  methods: {
    getCoinIdx(name) {
      var k = 0;
      var s = 0;
      this.coinData.forEach((element) => {
        if (element.Name == name) {
          s = k;
        } else {
          k++;
        }
      });
      console.log("returning " + s);
      return s;
    },
    getActiveAccount: function () {
      if (this.activeAccount) {
        return (
          this.activeAccount.substring(0, 7) +
          "..." +
          this.activeAccount.substring(
            this.activeAccount.length - 5,
            this.activeAccount.length
          )
        );
      }
    },
    disconnectWallet() {
      this.client.clearActiveAccount();
      window.location.reload();
    },
    connectWallet() {
      this.client.clearActiveAccount();

      this.client.getActiveAccount().then((activeAccount) => {
        if (activeAccount) {
          this.activeAccount = activeAccount.address;
          // If we have an active account, send the delegate operation directly
          this.getAccountBalance();
        } else {
          // If we don't have an active account, we need to request permissions first and then send the delegate operation
          this.client.requestPermissions().then(() => {
            this.client.getActiveAccount().then((activeAccount) => {
              this.activeAccount = activeAccount.address;
              this.getAccountBalance();
            });
          });
        }
      });
    },
    changeSelected() {
      this.modelLoadProgress = 0;
      this.glbSource =
        "https://delphin.fra1.cdn.digitaloceanspaces.com/numitzmatics/%23N" +
        this.selectedCoin.Name.substring(2, 7) +
        ".glb";
      this.selectedIndex = this.getCoinIdx(this.selectedCoin.Name);
      this.$forceUpdate();
    },
    nextCoin() {
      this.selectedIndex = this.getCoinIdx(this.selectedCoin.Name);
      if (this.selectedIndex < this.coinData.length) {
        this.selectedIndex = this.selectedIndex + 1;
        this.selectedCoin = this.coinData[this.selectedIndex];
        this.changeSelected();
        this.$nextTick(() => {});
      }
    },
    prevCoin() {
      this.selectedIndex = this.getCoinIdx(this.selectedCoin.Name);
      if (this.selectedIndex > 0) {
        this.selectedIndex = this.selectedIndex - 1;
        this.selectedCoin = this.coinData[this.selectedIndex];
        this.changeSelected();
        this.$nextTick(() => {});
      }
    },
    modelProgress(e) {
      this.modelLoadProgress = Math.round(100 * e.detail.totalProgress);
    },
    getAccountBalance() {
      const url = `https://api.tzkt.io/v1/tokens/balances?token.contract.eq=${this.CONTRACT_ADDRESS}&account.eq=${this.activeAccount}&select=balance,token.metadata.name,token.metadata.description,token.metadata.attributes&limit=10000`;
      fetch(url).then((r) => {
        r.json().then((b) => {
          /// Build coindata
          b.forEach((t) => {
            if (parseInt(t.balance) > 0) {
              let Origin = "";
              let Date = "";
              let Reverse = "";
              let Obverse = "";
              let Type = "";
              let Material = "";
              t["token.metadata.attributes"].forEach((a) => {
                if (a.name == "Origin") {
                  Origin = a.value;
                } else if (a.name == "Date") {
                  Date = a.value;
                } else if (a.name == "Reverse") {
                  Reverse = a.value;
                } else if (a.name == "Obverse") {
                  Obverse = a.value;
                } else if (a.name == "Type") {
                  Type = a.value;
                } else if (a.name == "Material") {
                  Material = a.value;
                }
              });

              let o = {
                Name: t["token.metadata.name"],
                Desc: t["token.metadata.description"],
                Origin: Origin,
                Type: Type,
                Material: Material,
                Date: Date,
                Reverse: Reverse,
                Obverse: Obverse,
              };
              this.coinData.push(o);
            }
          });
          if (this.coinData.length > 0) {
            this.selectedIndex = 0;
            this.selectedCoin = this.coinData[0];
            this.changeSelected();
          }
        });
      });
    },
  },
};
</script>

<style>
body {
  background: linear-gradient(
    109.6deg,
    rgb(36, 45, 57) 11.2%,
    rgb(16, 37, 60) 51.2%,
    rgb(0, 0, 0) 98.6%
  );
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  margin: 0px;
  padding: 0px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#top-right {
  z-index: 1000;
  /*text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;*/
  font-size: 0.75em;
  font-family: "Scope One", serif;
  position: fixed;
  top: 10px;
  right: 20px;
}

#top-left {
  color: #d2d5d4;
  z-index: 1000;
  /*text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;*/
  font-size: 0.75em;
  font-family: "Scope One", serif;
  position: fixed;
  top: 10px;
  left: 20px;
}

#bottom-right {
  z-index: 1000;
  /*text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;*/
  font-size: 0.65em;
  font-family: "Scope One", serif;
  position: fixed;
  bottom: 10px;
  right: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#bottom-left {
  z-index: 1000;
  font-family: "Scope One", serif;
  position: fixed;
  bottom: 2px;
  left: 15px;
  color: #d2d5d4 !important;
}

#bottom-left:hover {
  opacity: 85%;
}

.opensea-link {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  color: white;
  font-size: 0.65em;
  text-decoration: none;
}

.notokens-link {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  color: white;
  text-decoration: none;
}

.glass {
  /* From https://css.glass */
  background: rgba(6, 1, 28, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(6, 1, 28, 0.3);
}
</style>
